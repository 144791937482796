import { css, useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { BlackButton } from "./layout/ReusableComponents"

const Display = styled.div`
  margin-bottom: 32px;

  @media (min-width: 768px) {
    display: flex;
    align-items: stretch;
  }

  @media (min-width: 1280px) {
    display: ${({ firstPost }) => (firstPost ? "block" : "flex")};
    align-items: ${({ desktopGrid }) => (desktopGrid ? "center" : "stretch")};
    grid-row: ${({ firstPost }) => (firstPost ? "span 3" : "span 1")};
    margin-right: ${({ firstPost }) => (firstPost ? "16px" : 0)};
    margin-bottom: ${({ desktopGrid, firstPost }) =>
      desktopGrid || firstPost ? 0 : "32px"};
  }
`

const Details = styled.div`
  @media (min-width: 768px) and (max-width: 1279px) {
    margin-left: 32px;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;

  }
  @media (min-width: 1280px) {
    justify-content: ${({ desktopGrid }) =>
      desktopGrid ? "center" : "space-between"};
    padding: ${({ desktopGrid, firstPost }) =>
      desktopGrid || firstPost ? 0 : "16px 0"};
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 1.33em;
  color: ${({ theme }) => theme.dark};
  margin: 32px 0 8px;
  text-transform: capitalize;

  @media (min-width: 768px) and (max-width: 1279px) {
    margin-top: 0;
  }

  @media (min-width: 1280px) {
    margin-top: ${({ desktopGrid }) => (desktopGrid ? 0 : "32px")};
  }
`

const Date = styled.p`
  font-size: 0.875rem;
  margin: 8px 0 0;
  color: ${({ theme }) => theme.lightGrey};
`

export default function BlogPostSingleDisplay({
  cover,
  title,
  slug,
  date,
  excerpt,
  firstPost,
  desktopGrid,
}) {
  const theme = useTheme()
  return (
    <Display desktopGrid={desktopGrid} firstPost={firstPost}>
      <GatsbyImage
        image={getImage(cover)}
        alt={title}
        css={css`
          height: 40vw;
          width: 100%;
          max-height: 456px;
          min-height: 150px;

          @media (min-width: 1280px) {
            min-width: 208px;
            max-width: ${desktopGrid ? "208px" : "712px"};
            margin-right: 32px;
            max-height: ${desktopGrid ? "208px" : "456px"};
          }
        `}
      />

      <Details desktopGrid={desktopGrid} firstPost={firstPost}>
        <Date
          css={css`
            display: none;

            @media (min-width: 768px) {
              display: block;
            }

            @media (min-width: 1280px) {
              display: ${desktopGrid || firstPost ? "none" : "block"};
            }
          `}
        >
          {date}
        </Date>

        <Link to={slug}>
          <Title desktopGrid={desktopGrid}>{title}</Title>
        </Link>

        <p
          css={css`
            margin: 0;
            color: ${theme.grey};
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          `}
        >
          {excerpt}
        </p>

        <Date
          css={css`
            @media (min-width: 768px) {
              display: none;

              @media (min-width: 1280px) {
                display: ${firstPost || desktopGrid ? "block" : "none"};
              }
            }
          `}
        >
          {date}
        </Date>

        <BlackButton
          as={Link}
          to={slug}
          css={css`
            margin: 16px 0 0;

            @media (min-width: 1280px) {
              display: ${desktopGrid ? "none" : "block"};
            }
          `}
        >
          Read More
        </BlackButton>
      </Details>
    </Display>
  )
}
